.menuDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.folderButton {
  background-color: #e0e0f0;
  color: black;
  text-align: left;
  padding: 5px 10px;

  width: 100%;
}

.itemButton {
  background-color: white;
  color: black;
  text-align: left;
  padding: 5px 10px;
  width: 100%;

  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.itemButton:visited {
  color: black;
}

.activeItemButton {
  text-align: left;
  padding: 5px 10px;
  width: 100%;

  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.activeItemButton:visited {
  color: black;
}