.background {
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 100%;


}
/* Set the dimensions and margins of the A4 paper container */
.a4-paper {
  width: 210mm;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  min-height: 279mm;

}

/* Style the main content */
.content {
  padding: 20px;
}

.imageGrid {
    display: grid;
    grid-gap: 0px;
    /*justify-items: center;*/
    padding-right: 0px;
    padding-left: 0px;
  }
  
  .link {
    text-align: right;
    color: silver;
    font-size: smaller;
    text-decoration: underline;
  }