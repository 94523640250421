.selectValid {
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }
  
  .selectNotValid {
    width: 100%;
    margin: 0;
  
    box-sizing: border-box;
    color: gray;
  }
  
  .td {
    padding: 0;
  }
  