.card {
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  background-color: white;
  width: 300mm;
  margin: 0 auto;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Adjust the styles for screens with a width less than 210mm */
@media (max-width: 300mm) {
  .card {
    width: 100%;
    margin: 0;
  }
}
