.div {
  width: 100%;
  padding: 10px;
}

.div p {
  text-align: left;
  /* CSS properties for paragraphs */
  font-size: 16px;
  line-height: 1.5;
  margin: 0 0 1em;
}

.div img {
  display: block;
  margin: 0 auto;
}

.background {
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 100%;
}
