.text-entry-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom:10px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

label {
  margin-bottom: 0.5rem;
}

input {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  max-width: 320px;
}

.textArea {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  width: 320px;
  border-radius: 3px;
  resize: both;
}
