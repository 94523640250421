.container {
  display: flex;
}

.decbutton {
  width: 25px;
  height: 25px;
  grid-column: 1;
}

.incbutton {
  width: 25px;
  height: 25px;
  grid-column: 2;
}

.btnstackcontainer {
  padding-top: 10px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.btncontainer {
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-flow: column;
  width: 50px;
}

.chartimg {
  padding-left: 0px;
}
