.container {
    display: flex;
  }
  
  .decbutton {
    width: 25px;
    height: 25px;
    font-size: 16px;
    padding: 0;
    color: #fff;
    font-weight: bold;

    background-color: #007bff;
    border: none;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    grid-column: 1;
  }
  
  .incbutton {
    width: 25px;
    height: 25px;
    font-size: 16px;

    padding: 0;
    color: #fff;
    font-weight: bold;
    background-color: #007bff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    grid-column: 2;
  }
  
  .btnstackcontainer {
    padding-top: 10px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  
  .btncontainer {
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-flow: column;
    width: 50px;
    z-index: 2;
  }
  
  .chartimg {
    z-index: 1;
    padding-left: 0px;
    margin-left: -15px; /*overlap the image slightly*/
  }
  
  .lowerButtonContainer {
    display: flex;
    padding-top: 3px;
    justify-content: space-around;
  }

  .innerBox {
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    border-color: black;
    border-style: solid;
    border-width: 2px;
  }

  .outerBox {
    padding-top: 10px;
    padding-bottom: 10px;
  }