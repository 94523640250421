body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  background-color: #f1f2f2;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

}

/* Remove default styles */
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: none;
  padding: 8px;
  font-size: 14px;
  color: #333;
}

/* Add custom styles */
select {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 2px 8px rgba(0, 123, 255, 0.4);
}

/* Add an arrow icon */
select {
  background-image: url('images/arrow-down.png');
  background-repeat: no-repeat;
  background-position: right 8px center;
  padding-right: 24px;
}

/* Scale down the icon */
select::-ms-expand {
  display: none;
}

/* Style the dropdown options */
select option {
  padding: 8px;
}

/* Style the dropdown when open */
select option:checked {
  background-color: #007bff;
  color: #fff;
}

/* Customize the scrollbar track */
::-webkit-scrollbar {
  width: 10px; /* Adjust the width as needed */
  background-color: #f1f2f2; /* Set the background color of the scrollbar track */
}

/* Customize the scrollbar thumb (the draggable handle) */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar thumb */
}

/* Customize the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the scrollbar thumb on hover */
}

/* Customize the scrollbar corner */
::-webkit-scrollbar-corner {
  background-color: #f1f2f2; /* Set the background color of the scrollbar corner */
}

/* Style regular links */
a {
  color: #0366d6; /* Set link color */
  text-decoration: none; /* Remove default underline */
}

/* Style visited links */
a:visited {
  color: #8f8f8f; /* Set visited link color */
}

/* Style links on hover */
a:hover {
  text-decoration: underline; /* Add underline on hover */
}

/* Style links on active/focus */
a:active,
a:focus {
  outline: none; /* Remove default focus outline */
  text-decoration: underline; /* Add underline on active/focus */
}

/* Style the text input */
input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  background-color: #f8f8f8;
}

/* Style the text input on focus */
input:focus {
  outline: none;
  border-color: #0366d6;
  box-shadow: 0 0 4px rgba(3, 102, 214, 0.3);
}

/* Style the placeholder text */
input::placeholder {
  color: #999;
}

/* Style the text input on hover */
input:hover {
  border-color: #999;
}

/* Style the textarea */
textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  background-color: #f8f8f8;
  resize: vertical; /* Allow vertical resizing */
}

/* Style the textarea on focus */
textarea:focus {
  outline: none;
  border-color: #0366d6;
  box-shadow: 0 0 4px rgba(3, 102, 214, 0.3);
}

/* Style the placeholder text */
textarea::placeholder {
  color: #999;
}

/* Style the textarea on hover */
textarea:hover {
  border-color: #999;
}

textarea::placeholder {
  color: #999;
}