.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
}

.selected {
  background-color: #258;
  color: white;
  border: none;
  height: 3rem;
}

.normal {
  background-color: white;
  color: black;
  border: none;
  height: 3rem;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
}

.supportSelected {
  background-color: #258;
  color: white;
  border: none;
  height: 3rem;
}

.supportNormal {
  background-color: white;
  color: black;
  border: none;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  height: 3rem;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
}

.mobileButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  position: absolute;
  top: 50px; /* Adjust the top position as needed */
  left: 0px; /* Adjust the left position as needed */
  z-index: 9000; /* Set a high z-index to ensure it's on top of other elements */
  background-color: white; /* Set the background color to white */
}

.burger-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 3rem;
  height: 3rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 15px 5px;
}

.burger-menu.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 6px);
}

.burger-menu.open .bar:nth-child(2) {
  opacity: 0;
}

.burger-menu.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -6px);
}

.bar {
  width: 100%;
  height: 3px;
  background-color: #258;
  transition: transform 0.2s, opacity 0.2s;
}

/* Adjusted positioning */
.burger-menu .bar {
  margin: 0px 0;
  padding: 0px;
}

.burger-menu:not(.open) .bar:nth-child(1) {
  transform: translateY(-5px);
}

.burger-menu:not(.open) .bar:nth-child(3) {
  transform: translateY(5px);
}
