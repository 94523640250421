.button {
    display: inline-block;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bolder;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .button:active {
    background-color: #003d80;
    transform: translateY(1px);
  }
  
  .button:focus {
    outline: none;
    box-shadow: 0 2px 8px rgba(0, 123, 255, 0.4);
  }
  