
.select {
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }

.td {
    padding:0;
}
