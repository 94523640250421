
.select {
    width: 100%;
    box-sizing: border-box;
  }

.input {
    width: 100%;
    height: 100%;

    box-sizing: border-box;
}

.inputError {
    width: 100%;
    height: 100%;
    background-color: red;
    box-sizing: border-box;
}

.td {
    padding:0;
}

.row {
    padding:0;
}