
.container {
  /* set the position of the bottom center div within the parent div */
  width: 100%;
  height: 1rel;
  display:flex;
  justify-content: center;
  position: relative;
  bottom: 0;
  left: 50%; /* center the div horizontally by setting left to 50% */
  transform: translateX(-50%); /* adjust for the width of the div to center it perfectly */
  /* border: 1px solid black; */
}

.button {
  font-size: smaller;
  text-decoration: underline;
  background-color: white;
  color: #9A998C;
  border: none;
  height: 3rem;
}