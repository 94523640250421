/* Set the font family and size for the page */
.background {
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 100%;


}
/* Set the dimensions and margins of the A4 paper container */
.a4-paper {
  width: 210mm;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

/* Style the main content */
.content {
  padding: 20px;
}

/* Set the font family and size for the page */
.body {
  font-family: Arial, sans-serif;
  font-size: 12pt;
  line-height: 1.5;
}

.li {
  margin-bottom: 20px;
}

.lowerButtonContainer {
    display: flex;
    justify-content: space-around;
  }
