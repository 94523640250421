
/* Set the font family and size for the page */
.background {
    margin: 0;
    padding: 0;
}

/* Set the dimensions and margins of the A4 paper container */
.a4-paper {
  width: 210mm;
  min-height: 279mm;
  margin: 0 auto;
  background-color: white;

}
/* Adjust the styles for screens with a width less than 210mm */
@media (max-width: 210mm) {
  .a4-paper {
    width: 100%;
    margin: 0;
  }
}

/* Style the main content */
.content {
  padding: 20px;
}

.imageGrid {
    display: grid;
    grid-gap: 0px;
    /*justify-items: center;*/
    padding-right: 0px;
    padding-left: 0px;
  }
  
  .link {
    text-align: right;
    color: #9A998C;
    font-size: smaller;
    text-decoration: underline;
  }