
.container {
  display: flex;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

}

.scrollArea {
  padding: 0px;
  flex: 1; /* Make the right column take up as much space as possible */
  overflow: auto; /* Add scrollbars to the right column */
}

