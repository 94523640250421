.tableLayout {
    border-collapse: collapse;
  }

  .tableLayout td {
    border: 1px solid #f1f2f2;
    text-align: center;
    vertical-align: middle;
  }

  .select {
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }

  .button {
    width: 100%;
    box-sizing: border-box;
  }
 