.treeContainer {
  padding-left: 10px;
}
.container {
  display: flex;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

}

.leftColumn {
  flex: 0 1 300px; /* Set the width of the left column to 200px */

  overflow: auto; /* Add scrollbars to the left column */
}

.rightColumn {
  padding: 0px;
  flex: 1; /* Make the right column take up as much space as possible */
  overflow: auto; /* Add scrollbars to the right column */
}

.publishBorder {
  padding: 20px;
}

.mobileButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  position: absolute;
  top: 0px; /* Adjust the top position as needed */
  left: 0px; /* Adjust the left position as needed */
  z-index: 9000; /* Set a high z-index to ensure it's on top of other elements */
  background-color: white; /* Set the background color to white */
}
