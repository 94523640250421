/* Set the font family and size for the page */
.background {
    margin: 0;
    padding: 0;
    overflow: auto;
    height: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    /* Additional styles if needed */
    align-items: center; /* Align items horizontally at the center */
}

.mbutton {
    width: 100%;
    height: 100%;
}

.mslider {
    width: 100%;
    height: 100%;
}

.bpmButton {
    width: 100%;
    height: 100%;
    font-size: 40px;
    background-color: #000;

}


.mtable {
    width: 100%;
    max-width: 375px;
}