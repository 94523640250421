h1 {
  text-align: center;
  padding-bottom: 10px;
}

.centred {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.background {
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 100%;
}
/* Set the dimensions and margins of the A4 paper container */
.paper {
  width: 300mm;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 0px;
}

.howtouselink {
  display: flex;
  width: 100%;
  justify-content: center;
}

.container {
  margin-left: 20px;
  margin-right: 20px;
  padding: 0;
  overflow: auto;
  height: 100%;
}

/* Adjust the styles for screens with a width less than 210mm */
@media (max-width: 300mm) {
  .paper {
    width: 100%;
    margin: 0;
  }

  .container {
    margin-left: 0;
    margin-right: 0;
  }
}

.imageGrid {
  display: grid;
  grid-gap: 0px;
  justify-items: left;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
}

.howToUse {
  padding: 40px;
}

/* Adjust the styles for screens with a width less than 210mm */
@media (max-width: 300mm) {
  .howToUse ol {
    padding-left: 10px;
    padding-top: 10px;
  }
}
